body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --primary-color: rgba(246, 205, 24, 0.9);
  --font-family: 'Arial', sans-serif;
    --side-space:3vw;

}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}
/* thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 6px; 
} 


::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px; /* Border radius of the scrollbar track */
}

 ::-webkit-scrollbar-corner {
  background-color: #f1f1f1; 
} 
/* For Firefox */

