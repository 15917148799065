.circular-icon-border{
  width: 1cm; /* Set the width of the circular container */
height: 1cm; /* Set the height of the circular container */
display: flex;
align-items: center;
justify-content: center;
border: 2px solid #333; /* Border style: 2px solid, color: #333 (dark gray) */
border-radius: 50%
}
.form{
    border-top-right-radius:30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.round-button{
  background-color: var(--primary-color);
  width: 5cm;
  height:1.2cm;
  border:2px solid black;
  border-top-right-radius:50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;

}
.text-alignment{
    text-align: start;
    @media  (min-width: 15cm){
        text-align: center;
        
    }
}
.small-logo{
    height: 1.5cm;
    width: 1.5cm;
    border: 3px solid black;
    border-radius: 50%;
}
.big-logo{
    height: 2.5cm;
    width: 2.5cm;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
}
.popup{
    position: absolute;
    top: 2cm;
    background: white;
    height: 10cm;
}
.head{
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url('./images/chip.avif');
    height: 14cm;
    width:100vw;
    justify-content: center;
    align-items: center;
    padding-top: 2cm;
    padding-bottom: 1cm;
    border-bottom: 10px solid var(--primary-color); 
   @media(max-width: 12cm){
    height: 12cm;
   } 
}
.head-writeup{
    position: absolute;
    top: 0cm;
    width: 100%;
    height: 100%;
     background-color: rgba(0, 0, 0, 0.5);  
     padding-top: 1.5cm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
} 
.main-writeup{
    font-size: 40px;
    color: var(--primary-color);
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 900;
}
.sub-writeup{
    color: white;
    font: 2vw;
    text-align: center;
}

.text-gold{
    color:var(--primary-color)
}
.m-right-text{
    margin-right:0.5cm;
}

.navbar-wrapper .background-primary {
    background-color: var(--primary-color);
  }
  .background-primary {
    background-color: var(--primary-color);
  }
.counter{
    padding-top: 0.875cm;
    padding-bottom: 0.875cm;
}
 .counter-element{
    border-right: 2px solid black;

    @media (max-width:15cm){
        border-right: 0;
        border-bottom:  2px solid black;
        padding-bottom: 0.875cm;
    }
}
.counter-element2{

    @media (max-width:15cm){
        padding-top: 0.875cm;
    }
}

.card .text-transparent{
    color: transparent;
}
.card .card-text{
    margin-bottom: 0;
}

.navbar-wrapper .padding-side{
  padding-left: 0.7cm;
  padding-right: 0.7cm;
  @media (min-width: 768px) {
      padding-left: 1.3cm;
      padding-right: 1.3cm;
    }
}
.padding-side-responsive{
    padding-left: 0.7cm;
    padding-right: 0.7cm;
    @media (min-width: 768px) {
        padding-left: 5cm;
        padding-right: 5cm;
      }
  }
  .padding-side{
    padding-left: 0.7cm;
    padding-right: 0.7cm;
    /* @media (min-width: 768px) {
        padding-left: 1.3cm;
        padding-right: 1.3cm;
      } */
  }
  .card .round-border{
    background-color: var(--primary-color)
  }
  .form .custom-input{
    border:0;
    background: transparent;
    border-bottom: 3px solid black;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
  .form .custom-input:focus {
    box-shadow: none;
    background: transparent;
}
.solution1{
    background-image:url('./images/consultancySolution1.jpeg');
    width:100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.solution2{
    background-image: url('./images/pentestSolution.jpeg');
    width:100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.solution3{
    background-image:url('./images/dataprivacySolution.jpeg');
    width:100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.solution4{
    background-image: url('./images/managedSecuritySolution.jpeg');
    width:100%;
    margin-left: 0.2cm;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}


.bold-text {
    margin-bottom: 0%;
    font-weight: 700;
}
.carousel{
    width:100vw;
    margin-bottom: 0.875cm;
}
/* .padding{
    padding-left: 3vw;
    padding-right: 3vw;
} */
.wrapper{
    margin-top: 0.7cm;
}
.text-bar{
    width:2cm;
    height:0.2cm;
    background-color:var(--primary-color);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    margin-top: 0.175cm;
    margin-bottom: 0.175cm;
}

footer{
    background-color: gray;
}
.margin-vertical{
    margin-top: 0.875cm;
    margin-bottom: 1.7cm;
}
.margin-top{
    margin-top: 0.875cm;
}
.icon-size{
    height: 0.65cm;
    width: 0.65cm;
}
